// Basics
import { auth } from "./firebase";
import { useNavigate, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";


// Me
import ButtonAppBar from "./ButtonAppBar";
import BasicTable from "./Table"
import Grid from "@mui/material/Grid"


function Dashboard() {
  const [user, loading] = useAuthState(auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return navigate("/");
  }, [user, loading, navigate]);

  if (!loading) {
    return (
      <div className="dashboard">
        <div className="dashboard__container">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ButtonAppBar/>
            </Grid>
            {}
            <Grid item xs={12}>
              <Routes>
                <Route exact path="/" element={<BasicTable />} />
              </Routes>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  else {
    return
  }
}

export default Dashboard;