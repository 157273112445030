import React, { useState } from "react";

// MUI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';


// Me
import { updateStatics } from "./firebase";


export default function BasicModal(props) {
  const [open, setOpen] = useState(true);
  const [formValues, setFormValues] = useState(props.data.statics);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false)
    setFormValues(null)
    props.onClose()
  };


  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    console.log("Submitting")
    try {
      await updateStatics(props.data.id, formValues)
      handleClose()
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Statics</DialogTitle>
        <form onSubmit={handleSubmit}>
            <DialogContent>
            {Object.entries(props.data.statics).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)).map(([key, value]) => (
                <TextField
                    margin="dense"
                    id={key}
                    label={key}
                    name={key}
                    defaultValue={value}
                    fullWidth
                    variant="standard"
                    key={key}
                    onChange={handleChange}
                />
            ))}
            </DialogContent>
            <DialogActions>
              <Button key='cancelButton' onClick={handleClose}>Cancel</Button>
              <Button key='submitButton' type="submit">
                {loading ? <CircularProgress size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}