// Basics
import * as React from 'react';
import { logout } from "./firebase";

// MUI
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { getUserDoc } from './firebase';


// Me
import dcLogoWhite from './images/dcLogoWhite.png'

export default function ButtonAppBar() {
  const [open, setOpen] = useState(false);
  const [accountDetails, setAccountDetails] = useState(
    {
      name: "",
      email: "",
      accounts: [],
    }
  );

  const handleOpenModal = () => {
    const fetchUserDoc = async () => {
      const userData = await getUserDoc();
      setAccountDetails(userData);
    }
    fetchUserDoc();
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ justifyContent: 'space-between'}}>
        <Button onClick={handleOpenModal} color="inherit">Account</Button>
        <img alt="" src={dcLogoWhite} style={{ maxHeight: 65 }}/>
        <Button color="inherit" onClick={logout}>Log out</Button>
      </Toolbar>
      <div>
        <Dialog open={open} onClose={handleCloseModal}>
          <DialogTitle>Account</DialogTitle>
          <DialogContent>
            <div>Name: {accountDetails.name}</div>
            <div>Email: {accountDetails.email}</div>
            <div>Account(s): {accountDetails.accounts.join(',')}</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </AppBar>
  );
}