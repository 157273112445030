// Basics
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// MUI
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Me
import Login from "./auth/Login.js";
import Reset from "./auth/Reset";
import Dashboard from "./Dashboard";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="app">
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/reset" element={<Reset />} />
            <Route exact path="/dashboard/*" element={<Dashboard />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}
export default App;
