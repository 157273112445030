import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  doc,
  getDoc,
  getDocs,
  collection,
  addDoc,
  updateDoc,
  where
} from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyB5810-1huDZbsxGnH_KE3x1Aehjr3J4k4",
  authDomain: "dataconduit.firebaseapp.com",
  projectId: "dataconduit",
  storageBucket: "dataconduit.appspot.com",
  messagingSenderId: "39956770044",
  appId: "1:39956770044:web:0196c88c80836305bdbb79",
  measurementId: "G-5B580410KJ"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);


const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};


const logout = () => {
  signOut(auth);
};

const getUserDoc = async () => {
  const uid = auth.currentUser.uid
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const data = docSnap.data()
    return data
  } else {  
    console.log("No such document");
    return null
  }
}

// Db access
const getFunctions = async () => {
  const user = auth.currentUser;
  const uid = user.uid;
  
  const userDoc = await getDoc(doc(db, "users", uid))
  const account = userDoc.data().account
  const q = query(collection(db, "functions"), where("account", "==", account));

  var docs = []
  const querySnapshot = await getDocs(q);
  await Promise.all(querySnapshot.docs.map(async (gFunction) => {
    const data = gFunction.data()
    data.id = gFunction.id
    docs.push(data)
  }));
  return docs
}

const updateStatics = async (gFunction, statics) => {
  const collectionRef = collection(db, "functions");
  const docRef = doc(collectionRef, gFunction)
  try {
    await updateDoc(docRef, {
      statics: statics
    });
    console.log('Document updated successfully');
  } catch (error) {
    console.error('Error updating document: ', error);
  }
}

export {
  auth,
  db,
  registerWithEmailAndPassword,
  updateStatics,
  logout,
  getFunctions,
  getUserDoc
};