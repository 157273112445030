import React, { useEffect, useState } from "react";

// MUI
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import CircularProgress from '@mui/material/CircularProgress';


// Me
import Container from '@mui/material/Container';
import { getFunctions } from './firebase'
import BasicModal from "./modal";


export default function BasicTable() {
  
  const styles = {
    tableCell: {
      fontWeight: 'bold'
    },
    tableCellWide: {
      minWidth: 250,
    }

  };

  const [gFunctions, setFunctions] = useState([])
  
  // Modal control
  const [modalData, setModalData] = useState()
  const handleOpenModal = (event) => {
    const rowIndex = event.target.closest('tr').rowIndex
    const data = gFunctions[rowIndex - 1]
    setModalData(data)
  };
  const handleCloseModal = () => {
    setModalData(null);
  };


  useEffect(() => {
    const fetchData = async () => {
      const data = await getFunctions()
      setFunctions(data)
    }
    fetchData()
    .catch(console.error);
  }, [modalData])

  if (gFunctions.length === 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    )
  }
  else {
    const columns = [
      {
        name: "Functions",
        id: "function"
      },
      {
        name: "Input",
        id: "input"
      },
      {
        name: "Output",
        id: "output"
      },
      {
        name: "Description",
        id: "description"
      },
      {
        name: "Schedule",
        id: "schedule"
      }
    ];
    return (
      <Container maxWidth={'xl'}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((data) => (
                    <TableCell key={data.name} sx={styles.tableCell}>{data.name}</TableCell>
                ))}
                <TableCell sx={styles.tableCell} key='statics'>Statics</TableCell>
                <TableCell sx={styles.tableCell} key='edit' align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gFunctions.map((gFunction, index) => (
                <TableRow
                  key={gFunction.function}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {columns.map((data, i) => (
                    <TableCell component="th" scope="row" key={i}>{gFunction[data.id]}</TableCell>
                  ))}
                  <TableCell key={'staticsValues'} sx={styles.tableCellWide}>
                    <p>
                      {Object.entries(gFunction.statics).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)).map(([staticsKey, staticsValue], i) => (
                        <React.Fragment key={i}>
                          {`${staticsKey}: ${staticsValue}`} <br />
                        </React.Fragment>
                      ))}
                    </p>
                  </TableCell>
                  <TableCell align="right" key={index}>
                    <Button onClick={handleOpenModal} key='editButton'>Edit Statics</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {modalData && (
          <BasicModal
            onClose={handleCloseModal}
            data={modalData}
          />
        )}
      </Container>
    );
  }
}